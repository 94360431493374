header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  justify-items: center;
  align-items: center;
  width: 100%;
  min-height: 2.75rem;
  border-bottom: 1px solid #ccc;
}

header > :first-child {
  justify-self: start;
}

header > :last-child {
  justify-self: end;
}

header h1 {
  margin: 0;
}

header .icon {
  font-size: 1rem;
}
