button.icon-button {
  border: none;
  background: none;
  margin: 0.25rem;
  padding: 0;
  cursor: pointer;
  outline: none;
}

button.icon-button svg {
  fill: #ccc;
  background: none;
  border-radius: 50%;
}

button.icon-button:focus-visible svg,
button.icon-button:hover svg {
  fill: #333;
  background: #ccc;
}
