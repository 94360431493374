*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: "geologica";
  src: url("./assets/Geologica-VariableFont_CRSV,SHRP,slnt,wght.ttf");
}

body {
  background: #202027;
  color: #cccccc;
}

body,
input,
button,
textarea {
  font-family: "geologica", sans-serif;
}

h1,
h2,
h3 {
  margin: 0.25em 0;
}

p {
  margin: 0;
}

a {
  color: #ccc;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

/**
 * Hide an element visually but leave the content available for assistive technologies
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
